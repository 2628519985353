<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.username"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="类型：">
        <div style="width: 200px">
          <NSelect :options="typeOptions" v-model:value="formValue.type_status" />
        </div>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-button type="primary" class="account-btn" @click="NewlyAdded" v-if="ownUrlPermission('admin/assignment/create')">新增账号</n-button>
    <div class="account-null-btn"></div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />
    <!-- :row-key="rowKey" -->
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { AssignmentIndex, AssignmentUpdateStatus, AssignmentDelete } from "@/api/admin-home.js";
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

// import {
//   getTeachingList,
//   delTeaching,
//   batDelTeaching,
// } from "@/api/teaching.js";
const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "账号",
      key: "username",
      align: "center",
    },
    {
      title: "角色",
      key: "name",
    },
     {
      title: '状态',
      render: row => h(
        'span',
        null,
        { default: () => getResourceName(row.type_status) },
      )
    },
    {
      title: "备注",
      key: "remark",
    },
    {
      title: '操作',
      width: 300,
      key: "type_status",
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  if(data.type_status=="1"){
    data.type_status = 0
  }
  else if(data.type_status=="2"){
    data.type_status = 1
  }

  AssignmentIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});
};

// 状态
 function getResourceName(type_status) {
  return accountType[type_status] || '';
}

//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'primary', onClick: () => DisableEnable(row) }, { default: () => '启用' })
  let arr2 = h(NButton, { text: true, type: 'error', onClick: () => toDelLabel(row) }, { default: () => '删除' })
  let arr3 = h(NButton, { text: true, type: 'error', onClick: () => DisableEnable(row) }, { default: () => '停用' })
  let arr4 = h(NButton, { text: true, type: 'primary', onClick: () => toAddLabel(row) }, { default: () => '编辑' })
  if(row.type_status=="0"){
    // 账号状态修改
    if (ownUrlPermission('admin/assignment/update-status')){
      tempArr.push(arr1)
    }
    // 删除
    if (ownUrlPermission('admin/assignment/delete')){
      tempArr.push(arr2)
    }
    return tempArr;
    }else{
      // 账号状态修改
      if (ownUrlPermission('admin/assignment/update-status')){
        tempArr.push(arr3)
      }
      // 编辑
      if (ownUrlPermission('admin/assignment/update')){
        tempArr.push(arr4)
      }
      return tempArr;
    }
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(row) {
  // console.log("编辑",row.id)
  let id = row.id
  router.push({
    path:'/admin/assignment/index/addedit/',
    query:{id:id}
  })
}

// 新增
function NewlyAdded(row) {
  // console.log("编辑",row.id)
  let id = row.id
  router.push({
    path:'/admin/assignment/index/addedit/'
  })
}

 // 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {}
  data.type_status = row.type_status
  data["AdminModel[id]"] = row.id
  dialog.warning({
    title: '删除确认',
    content: `确定删除账号:“${row.username}”？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      AssignmentDelete(data).then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.info(res.msg);
          // 成功后 调用接口刷新页面
          updateList(toRaw(formValue))
        }
      })
      .catch((reject) => {})
    },
    onNegativeClick: () => {}
  });

  // 账号删除接口
    
}

  // 停用启用
  function DisableEnable(row){
    loading.value = true;
    var formDatas = new FormData()
    let data = {}
    data.id = row.id
    data.type_status = row.type_status == '0'?1 : 0
    AssignmentUpdateStatus(data).then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList(toRaw(formValue))
      }
    })
    .catch((reject) => {});
  }

// 翻页 搜索参数集
var ParameterSet = {}

// 监控标题输入
 function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

// 搜索
function handleSearch () {
  ParameterSet = toRaw(formValue)
  console.log("ParameterSet.type_status",ParameterSet.type_status)
  updateList(ParameterSet)
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;

};
const handlePageChange = (pageSize) => {
  pagination.pagesize = pageSize;
  formValue.pagesize = pageSize;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};

// 分页结束-------------------

const typeOptions = (() =>
  Object.keys(teachingTypesMap).map((value) => ({
    label: teachingTypesMap[value],
    value,
  })))();
const getDefaultFormValue = () => ({
  username: "",
  type_status: null,
  page: 1,
  pagesize: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  handleSearch();
};

defineExpose({
  getSearchParams,
});

updateList(toRaw(formValue));
</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
</style>